<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
            <v-select
              @on-change="paginateChange"
              :options="perPageOptions"
              v-model="search.paginate"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search.search_data"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="getToCreate">
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center align-middle">SL</th>
              <th class="align-middle">Name</th>
              <th class="align-middle text-center">Role</th>
              <th class="align-middle text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in users.data"
              :key="index"
              v-if="users.data.length"
            >
              <td class="text-center align-middle">
                {{ users.from + index }}
              </td>
              <td class="align-middle">
                {{ user.name }}
              </td>
              <td class="align-middle text-center">
                <b-badge
                  :variant="'success'"
                  class="text-capitalize"
                  v-if="user.role"
                >
                  {{ user.role.name }}
                </b-badge>
              </td>
              <td class="text-center align-middle">
                <b-button
                  variant="primary"
                  @click="goToEdit(user.id)"
                  :disabled="!permission.edit"
                  class="btn-sm"
                >
                  <span class="text-nowrap">Edit</span>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ users.from }} to {{ users.to }} of
              {{ users.total }} entries</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
              :data="users"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BBadge,
    vSelect,
  },
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],

      users: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    getToCreate() {
      this.$router.push("/users/create");
    },
    goToEdit(id) {
      this.$router.push("/users/edit/" + id);
    },
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const users = await this.callApi(
        "post",
        "/app/user?page=" + this.search.page,
        this.search
      );
      if (users.status == 200) {
        this.users = users.data.users;
      }
    },

    loadImage(e) {
      this.form.icon_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.icon_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "user.access") {
          this.permission.access = true;
        }

        if (permission.slug == "user.create") {
          this.permission.create = true;
        }

        if (permission.slug == "user.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "user.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>

<style></style>
